import { combineReducers } from "redux";
import { UserTypes } from "../../action_types";
import {
  arrayToObject,
  removeItem,
  insertItem,
  updateObjectInArray,
} from "@app/utils/helpers";

function summary(state = {}, action) {
  switch (action.type) {
    case UserTypes.GET_STATISTICS_SUCCESS:
      return { ...state, ...action.data };
    default:
      return state;
  }
}

function all(state = {}, action) {
  switch (action.type) {
    case UserTypes.GET_USERS_SUCCESS:
      return { ...state, ...arrayToObject(action.data.results) };
    case UserTypes.GET_USER_SUCCESS:
    case UserTypes.CREATE_USER_SUCCESS:
    case UserTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        [action.data.id]: action.data,
      };
    case UserTypes.DELETE_USER_SUCCESS:
      const { [action.id]: _, ...newState } = state;
      return newState;
    default:
      return state;
  }
}

function ui(state = [], action) {
  switch (action.type) {
    case UserTypes.GET_USERS_SUCCESS:
      return action.data.results;
    case UserTypes.CREATE_USER_SUCCESS:
      return insertItem(state, action.data);
    case UserTypes.UPDATE_USER_SUCCESS:
      return updateObjectInArray(state, action.data);
    case UserTypes.DELETE_USER_SUCCESS:
      return removeItem(state, action.id);
    default:
      return state;
  }
}

function meta(state = {}, action) {
  switch (action.type) {
    case UserTypes.GET_USERS_SUCCESS: {
      const { results, ...rest } = action.data;
      return { ...state, ...rest };
    }
    case UserTypes.CREATE_USER_SUCCESS:
      return { ...state, total: state.total + 1 };
    case UserTypes.DELETE_USER_SUCCESS:
      return { ...state, total: state.total - 1 };
    default:
      return state;
  }
}

export default combineReducers({
  summary,
  all,
  ui,
  meta,
});
