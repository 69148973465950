import { combineReducers } from "redux";
import auth from "./auth";
import contact from "./contact";
import user from "./user";
import company from "./company";
import common from "./common";
import invite from "./invite";
import device from "./device";
import checkin from "./checkin";
import visitor from "./visitor";
import image from "./image";
import billing from "./billing";
import question from "./question";
import building from "./building";

export default combineReducers({
  auth,
  contact,
  user,
  company,
  common,
  invite,
  device,
  checkin,
  visitor,
  image,
  billing,
  question,
  building,
});
