export default {
  GET_BUILDINGS_REQUEST: "GET_BUILDINGS_REQUEST",
  GET_BUILDINGS_SUCCESS: "GET_BUILDINGS_SUCCESS",
  GET_BUILDINGS_FAIL: "GET_BUILDINGS_FAIL",

  GET_BUILDING_REQUEST: "GET_BUILDING_REQUEST",
  GET_BUILDING_SUCCESS: "GET_BUILDING_SUCCESS",
  GET_BUILDING_FAIL: "GET_BUILDING_FAIL",

  UPDATE_BUILDING_REQUEST: "UPDATE_BUILDING_REQUEST",
  UPDATE_BUILDING_SUCCESS: "UPDATE_BUILDING_SUCCESS",
  UPDATE_BUILDING_FAIL: "UPDATE_BUILDING_FAIL",

  CREATE_BUILDING_REQUEST: "CREATE_BUILDING_REQUEST",
  CREATE_BUILDING_SUCCESS: "CREATE_BUILDING_SUCCESS",
  CREATE_BUILDING_FAIL: "CREATE_BUILDING_FAIL",

  DELETE_BUILDING_REQUEST: "DELETE_BUILDING_REQUEST",
  DELETE_BUILDING_SUCCESS: "DELETE_BUILDING_SUCCESS",
  DELETE_BUILDING_FAIL: "DELETE_BUILDING_FAIL",
};
