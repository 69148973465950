import { combineReducers } from "redux";
import { BuildingTypes } from "../../action_types";
import { arrayToObject, removeItem, insertItem, updateObjectInArray } from "@app/utils/helpers";

function all(state = {}, action) {
  switch (action.type) {
    case BuildingTypes.GET_BUILDINGS_SUCCESS:
      return { ...state, ...arrayToObject(action.data) };
    case BuildingTypes.GET_BUILDING_SUCCESS:
    case BuildingTypes.CREATE_BUILDING_SUCCESS:
    case BuildingTypes.UPDATE_BUILDING_SUCCESS:
      return {
        ...state,
        [action.data.id]: action.data,
      };
    case BuildingTypes.DELETE_BUILDING_SUCCESS:
      const { [action.id]: _, ...newState } = state;
      return newState;
    default:
      return state;
  }
}

function ui(state = [], action) {
  switch (action.type) {
    case BuildingTypes.GET_BUILDINGS_SUCCESS:
      return action.data;
    case BuildingTypes.CREATE_BUILDING_SUCCESS:
      return insertItem(state, action.data);
    case BuildingTypes.UPDATE_BUILDING_SUCCESS:
      return updateObjectInArray(state, action.data);
    case BuildingTypes.DELETE_BUILDING_SUCCESS:
      return removeItem(state, action.id);
    default:
      return state;
  }
}

export default combineReducers({
  all,
  ui,
});
