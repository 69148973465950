import { combineReducers } from "redux";
import { BuildingTypes } from "../../action_types";

import { handleRequest, initialRequestState } from "./helpers";

function getBuildings(state = initialRequestState(), action) {
  return handleRequest(
    BuildingTypes.GET_BUILDINGS_REQUEST,
    BuildingTypes.GET_BUILDINGS_SUCCESS,
    BuildingTypes.GET_BUILDINGS_FAIL,
    state,
    action
  );
}

function getBuilding(state = initialRequestState(), action) {
  return handleRequest(
    BuildingTypes.GET_BUILDING_REQUEST,
    BuildingTypes.GET_BUILDING_SUCCESS,
    BuildingTypes.GET_BUILDING_FAIL,
    state,
    action
  );
}

function createBuilding(state = initialRequestState(), action) {
  return handleRequest(
    BuildingTypes.CREATE_BUILDING_REQUEST,
    BuildingTypes.CREATE_BUILDING_SUCCESS,
    BuildingTypes.CREATE_BUILDING_FAIL,
    state,
    action
  );
}

function updateBuilding(state = initialRequestState(), action) {
  return handleRequest(
    BuildingTypes.UPDATE_BUILDING_REQUEST,
    BuildingTypes.UPDATE_BUILDING_SUCCESS,
    BuildingTypes.UPDATE_BUILDING_FAIL,
    state,
    action
  );
}

function deleteBuilding(state = initialRequestState(), action) {
  return handleRequest(
    BuildingTypes.DELETE_BUILDING_REQUEST,
    BuildingTypes.DELETE_BUILDING_SUCCESS,
    BuildingTypes.DELETE_BUILDING_FAIL,
    state,
    action
  );
}

export default combineReducers({
  getBuildings,
  getBuilding,
  createBuilding,
  updateBuilding,
  deleteBuilding,
});
