import { combineReducers } from "redux";
import { AuthTypes } from "../../action_types";

import { handleRequest, initialRequestState } from "./helpers";

function login(state = initialRequestState(), action) {
  return handleRequest(AuthTypes.LOGIN_REQUEST, AuthTypes.LOGIN_SUCCESS, AuthTypes.LOGIN_FAIL, state, action);
}

function checkLogin(state = initialRequestState(), action) {
  return handleRequest(
    AuthTypes.CHECK_LOGIN_REQUEST,
    AuthTypes.CHECK_LOGIN_SUCCESS,
    AuthTypes.CHECK_LOGIN_FAIL,
    state,
    action
  );
}

function logout(state = initialRequestState(), action) {
  return handleRequest(AuthTypes.LOGOUT_REQUEST, AuthTypes.LOGOUT_SUCCESS, AuthTypes.LOGOUT_FAIL, state, action);
}

function resetPassword(state = initialRequestState(), action) {
  return handleRequest(
    AuthTypes.RESET_PASSWORD_REQUEST,
    AuthTypes.RESET_PASSWORD_SUCCESS,
    AuthTypes.RESET_PASSWORD_FAIL,
    state,
    action
  );
}

function resetPasswordConfirm(state = initialRequestState(), action) {
  return handleRequest(
    AuthTypes.RESET_PASSWORD_CONFIRM_REQUEST,
    AuthTypes.RESET_PASSWORD_CONFIRM_SUCCESS,
    AuthTypes.RESET_PASSWORD_CONFIRM_FAIL,
    state,
    action
  );
}

export default combineReducers({
  login,
  checkLogin,
  logout,
  resetPassword,
  resetPasswordConfirm,
});
