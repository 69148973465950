import { combineReducers } from "redux";
import { ImageTypes } from "../../action_types";

function id_image(state = {}, action) {
  switch (action.type) {
    case ImageTypes.GET_ID_IMAGE_SUCCESS:
      return {
        ...state,
        [action.id]: action.data,
      };
    default:
      return state;
  }
}

function selfie_image(state = {}, action) {
  switch (action.type) {
    case ImageTypes.GET_SELFIE_IMAGE_SUCCESS:
      return {
        ...state,
        [action.id]: action.data,
      };
    default:
      return state;
  }
}

export default combineReducers({
  id_image,
  selfie_image,
});
