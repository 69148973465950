import { combineReducers } from "redux";
import { ContactTypes } from "../../action_types";

import { handleRequest, initialRequestState } from "./helpers";

function getContact(state = initialRequestState(), action) {
  return handleRequest(
    ContactTypes.GET_CONTACT_REQUEST,
    ContactTypes.GET_CONTACT_SUCCESS,
    ContactTypes.GET_CONTACT_FAIL,
    state,
    action
  );
}

function updateContact(state = initialRequestState(), action) {
  return handleRequest(
    ContactTypes.UPDATE_CONTACT_REQUEST,
    ContactTypes.UPDATE_CONTACT_SUCCESS,
    ContactTypes.UPDATE_CONTACT_FAIL,
    state,
    action
  );
}

function deleteContact(state = initialRequestState(), action) {
  return handleRequest(
    ContactTypes.DELETE_CONTACT_REQUEST,
    ContactTypes.DELETE_CONTACT_SUCCESS,
    ContactTypes.DELETE_CONTACT_FAIL,
    state,
    action
  );
}

function blockContact(state = initialRequestState(), action) {
  return handleRequest(
    ContactTypes.BLOCK_CONTACT_REQUEST,
    ContactTypes.BLOCK_CONTACT_SUCCESS,
    ContactTypes.BLOCK_CONTACT_FAIL,
    state,
    action
  );
}

function searchContacts(state = initialRequestState(), action) {
  return handleRequest(
    ContactTypes.SEARCH_CONTACTS_REQUEST,
    ContactTypes.SEARCH_CONTACTS_SUCCESS,
    ContactTypes.SEARCH_CONTACTS_FAIL,
    state,
    action
  );
}

function confirmContact(state = initialRequestState(), action) {
  return handleRequest(
    ContactTypes.CONFIRM_CONTACT_REQUEST,
    ContactTypes.CONFIRM_CONTACT_SUCCESS,
    ContactTypes.CONFIRM_CONTACT_FAIL,
    state,
    action
  );
}

function checkInContact(state = initialRequestState(), action) {
  return handleRequest(
    ContactTypes.CHECK_IN_CONTACT_REQUEST,
    ContactTypes.CHECK_IN_CONTACT_SUCCESS,
    ContactTypes.CHECK_IN_CONTACT_FAIL,
    state,
    action
  );
}
export default combineReducers({
  getContact,
  updateContact,
  deleteContact,
  blockContact,
  searchContacts,
  confirmContact,
  checkInContact
});
