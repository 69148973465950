const isLoggedIn = () => {
  return localStorage.getItem("token");
};

const setUser = user => {
  const { auth_token: token } = user;
  localStorage.setItem("token", token);
};

const getToken = () => {
  if (localStorage.getItem("token")) {
    return localStorage.getItem("token");
  }
  return sessionStorage.getItem("token");
};

const logout = () => {
  localStorage.removeItem("token");
};

const login = user => {
  setUser(user);
};

export default {
  login,
  logout,
  isLoggedIn,
  getToken,
};
