import { combineReducers } from "redux";
import { CheckinTypes } from "../../action_types";

import { handleRequest, initialRequestState } from "./helpers";

function getCheckins(state = initialRequestState(), action) {
  return handleRequest(
    CheckinTypes.GET_CHECKINS_REQUEST,
    CheckinTypes.GET_CHECKINS_SUCCESS,
    CheckinTypes.GET_CHECKINS_FAIL,
    state,
    action
  );
}

function getCheckin(state = initialRequestState(), action) {
  return handleRequest(
    CheckinTypes.GET_CHECKIN_REQUEST,
    CheckinTypes.GET_CHECKIN_SUCCESS,
    CheckinTypes.GET_CHECKIN_FAIL,
    state,
    action
  );
}

function createCheckin(state = initialRequestState(), action) {
  return handleRequest(
    CheckinTypes.CREATE_CHECKIN_REQUEST,
    CheckinTypes.CREATE_CHECKIN_SUCCESS,
    CheckinTypes.CREATE_CHECKIN_FAIL,
    state,
    action
  );
}

function updateCheckin(state = initialRequestState(), action) {
  return handleRequest(
    CheckinTypes.UPDATE_CHECKIN_REQUEST,
    CheckinTypes.UPDATE_CHECKIN_SUCCESS,
    CheckinTypes.UPDATE_CHECKIN_FAIL,
    state,
    action
  );
}

function updateCheckinAndVisitor(state = initialRequestState(), action) {
  return handleRequest(
    CheckinTypes.UPDATE_CHECKIN_AND_VISITOR_REQUEST,
    CheckinTypes.UPDATE_CHECKIN_AND_VISITOR_SUCCESS,
    CheckinTypes.UPDATE_CHECKIN_AND_VISITOR_FAIL,
    state,
    action
  );
}

function deleteCheckin(state = initialRequestState(), action) {
  return handleRequest(
    CheckinTypes.DELETE_CHECKIN_REQUEST,
    CheckinTypes.DELETE_CHECKIN_SUCCESS,
    CheckinTypes.DELETE_CHECKIN_FAIL,
    state,
    action
  );
}

function declineCheckin(state = initialRequestState(), action) {
  return handleRequest(
    CheckinTypes.DECLINE_CHECKIN_REQUEST,
    CheckinTypes.DECLINE_CHECKIN_SUCCESS,
    CheckinTypes.DECLINE_CHECKIN_FAIL,
    state,
    action
  );
}

function getCheckinHistory(state = initialRequestState(), action) {
  return handleRequest(
    CheckinTypes.GET_CHECKIN_HISTORY_REQUEST,
    CheckinTypes.GET_CHECKIN_HISTORY_SUCCESS,
    CheckinTypes.GET_CHECKIN_HISTORY_FAIL,
    state,
    action
  );
}

function exportCheckinsExcel(state = initialRequestState(), action) {
  return handleRequest(
    CheckinTypes.EXPORT_CHECKINS_EXCEL_REQUEST,
    CheckinTypes.EXPORT_CHECKINS_EXCEL_SUCCESS,
    CheckinTypes.EXPORT_CHECKINS_EXCEL_FAIL,
    state,
    action
  );
}
export default combineReducers({
  getCheckins,
  getCheckin,
  createCheckin,
  updateCheckin,
  updateCheckinAndVisitor,
  deleteCheckin,
  declineCheckin,
  getCheckinHistory,
  exportCheckinsExcel,
});
