export default {
  GET_CONTACT_REQUEST: "GET_CONTACT_REQUEST",
  GET_CONTACT_SUCCESS: "GET_CONTACT_SUCCESS",
  GET_CONTACT_FAIL: "GET_CONTACT_FAIL",

  UPDATE_CONTACT_REQUEST: "UPDATE_CONTACT_REQUEST",
  UPDATE_CONTACT_SUCCESS: "UPDATE_CONTACT_SUCCESS",
  UPDATE_CONTACT_FAIL: "UPDATE_CONTACT_FAIL",

  DELETE_CONTACT_REQUEST: "DELETE_CONTACT_REQUEST",
  DELETE_CONTACT_SUCCESS: "DELETE_CONTACT_SUCCESS",
  DELETE_CONTACT_FAIL: "DELETE_CONTACT_FAIL",

  BLOCK_CONTACT_REQUEST: "BLOCK_CONTACT_REQUEST",
  BLOCK_CONTACT_SUCCESS: "BLOCK_CONTACT_SUCCESS",
  BLOCK_CONTACT_FAIL: "BLOCK_CONTACT_FAIL",

  SEARCH_CONTACTS_REQUEST: "SEARCH_CONTACTS_REQUEST",
  SEARCH_CONTACTS_SUCCESS: "SEARCH_CONTACTS_SUCCESS",
  SEARCH_CONTACTS_FAIL: "SEARCH_CONTACTS_FAIL",

  CONFIRM_CONTACT_REQUEST: "CONFIRM_CONTACT_REQUEST",
  CONFIRM_CONTACT_SUCCESS: "CONFIRM_CONTACT_SUCCESS",
  CONFIRM_CONTACT_FAIL: "CONFIRM_CONTACT_FAIL",

  CHECK_IN_CONTACT_REQUEST: "CHECK_IN_CONTACT_REQUEST",
  CHECK_IN_CONTACT_SUCCESS: "CHECK_IN_CONTACT_SUCCESS",
  CHECK_IN_CONTACT_FAIL: "CHECK_IN_CONTACT_FAIL",
};
