import { combineReducers } from "redux";
import { CheckinTypes } from "../../action_types";
import { arrayToObject, removeItem, insertItem, updateObjectInArray } from "@app/utils/helpers";

function all(state = {}, action) {
  switch (action.type) {
    case CheckinTypes.GET_CHECKINS_SUCCESS:
      return { ...state, ...arrayToObject(action.data.results) };
    case CheckinTypes.GET_CHECKIN_SUCCESS:
    case CheckinTypes.CREATE_CHECKIN_SUCCESS:
    case CheckinTypes.UPDATE_CHECKIN_SUCCESS:
    case CheckinTypes.DECLINE_CHECKIN_SUCCESS:
      return {
        ...state,
        [action.data.id]: action.data,
      };
    case CheckinTypes.UPDATE_CHECKIN_AND_VISITOR_SUCCESS:
      return {
        ...state,
        [action.data[0].id]: action.data[0],
      };
    case CheckinTypes.DELETE_CHECKIN_SUCCESS:
      const { [action.id]: _, ...newState } = state;
      return newState;
    default:
      return state;
  }
}

function ui(state = [], action) {
  switch (action.type) {
    case CheckinTypes.GET_CHECKINS_SUCCESS:
      return action.data.results;
    case CheckinTypes.CREATE_CHECKIN_SUCCESS:
      return insertItem(state, action.data);
    case CheckinTypes.UPDATE_CHECKIN_SUCCESS:
    case CheckinTypes.DECLINE_CHECKIN_SUCCESS:
      return updateObjectInArray(state, action.data);
    case CheckinTypes.UPDATE_CHECKIN_AND_VISITOR_SUCCESS:
      return updateObjectInArray(state, action.data[0]);
    case CheckinTypes.DELETE_CHECKIN_SUCCESS:
      return removeItem(state, action.id);
    default:
      return state;
  }
}

function meta(state = {}, action) {
  switch (action.type) {
    case CheckinTypes.GET_CHECKINS_SUCCESS:
      const { results, ...rest } = action.data;
      return { ...state, ...rest };
    case CheckinTypes.DELETE_CHECKIN_SUCCESS: {
      const { total } = state;
      return {
        ...state,
        total: total - 1,
        deletedId: action.id,
      };
    }
    default:
      return state;
  }
}

function history(state = {}, action) {
  switch (action.type) {
    case CheckinTypes.GET_CHECKIN_HISTORY_SUCCESS:
      const { visitor_id } = action;
      return {
        ...state,
        [visitor_id]: [...(state[visitor_id] || []), ...action.data.results],
      };
    default:
      return state;
  }
}

function meta_history(state = {}, action) {
  switch (action.type) {
    case CheckinTypes.GET_CHECKIN_HISTORY_SUCCESS:
      const { visitor_id, data } = action;
      const { results, ...rest } = data;
      return {
        ...state,
        [visitor_id]: { ...state[visitor_id], ...rest },
      };
    default:
      return state;
  }
}

export default combineReducers({
  all,
  ui,
  meta,
  history,
  meta_history,
});
