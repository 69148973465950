export default {
  GET_USERS_REQUEST: "GET_USERS_REQUEST",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_FAIL: "GET_USERS_FAIL",

  GET_USER_REQUEST: "GET_USER_REQUEST",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_FAIL: "GET_USER_FAIL",

  UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAIL: "UPDATE_USER_FAIL",

  CREATE_USER_REQUEST: "CREATE_USER_REQUEST",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_FAIL: "CREATE_USER_FAIL",

  DELETE_USER_REQUEST: "DELETE_USER_REQUEST",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_FAIL: "DELETE_USER_FAIL",

  IMPORT_STAFFS_EXCEL_REQUEST: "IMPORT_STAFFS_EXCEL_REQUEST",
  IMPORT_STAFFS_EXCEL_SUCCESS: "IMPORT_STAFFS_EXCEL_SUCCESS",
  IMPORT_STAFFS_EXCEL_FAIL: "IMPORT_STAFFS_EXCEL_FAIL",

  EXPORT_STAFFS_EXCEL_REQUEST: "EXPORT_STAFFS_EXCEL_REQUEST",
  EXPORT_STAFFS_EXCEL_SUCCESS: "EXPORT_STAFFS_EXCEL_SUCCESS",
  EXPORT_STAFFS_EXCEL_FAIL: "EXPORT_STAFFS_EXCEL_FAIL",

  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAIL: "CHANGE_PASSWORD_FAIL",

  GET_STATISTICS_REQUEST: "GET_STATISTICS_REQUEST",
  GET_STATISTICS_SUCCESS: "GET_STATISTICS_SUCCESS",
  GET_STATISTICS_FAIL: "GET_STATISTICS_FAIL",
};
