import axios from "axios";
/* eslint-disable no-throw-literal */

export default class Client {
  constructor() {
    this.url = "";
    this.urlVersion = "";
    this.token = "";
  }

  paramsHelper = Obj => {
    const paramsStr = Object.keys(Obj).reduce((str, key) => {
      const paramStr = Obj[key] ? `&${key}=${Obj[key]}` : "";
      return str + paramStr;
    }, "");

    return paramsStr;
  };

  removeFalsy = obj => {
    let newObj = {};
    Object.keys(obj).forEach(prop => {
      if (!!obj[prop]) {
        newObj[prop] = obj[prop];
      }
    });
    return newObj;
  };

  getUrl = () => {
    return this.url;
  };

  setUrl = url => {
    this.url = url;
  };

  getToken = () => {
    return this.token;
  };

  setToken = token => {
    this.token = token;
  };

  getBaseRoute = () => {
    return `${this.url}${this.urlVersion}`;
  };

  getUserRoute = () => {
    return `${this.getBaseRoute()}/users`;
  };

  getBuildingRoute = () => {
    return `${this.getBaseRoute()}/buildings`;
  };

  getVisitorRoute = () => {
    return `${this.getBaseRoute()}/visitors`;
  };

  getQuestionRoute = () => {
    return `${this.getBaseRoute()}/questions`;
  };

  getCheckinRoute = () => {
    return `${this.getBaseRoute()}/visitor_entries`;
  };

  getDeviceRoute = () => {
    return `${this.getBaseRoute()}/devices`;
  };

  getInviteRoute = () => {
    return `${this.getBaseRoute()}/invites`;
  };

  getCompanyRoute = () => {
    return `${this.getBaseRoute()}/companies`;
  };

  getContactRoute = () => {
    return `${this.getBaseRoute()}/contacts`;
  };

  getAuthRoute = () => {
    return `${this.getBaseRoute()}/user`;
  };

  // auth
  signup = token => {
    return this.doFetch("post", `${this.getUserRoute()}`, { token });
  };

  checkLogin = (email, password) => {
    let formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    return this.doFetchWithoutToken("post", `${this.getBaseRoute()}/check_login`, formData);
  };

  login = (email, password, otp) => {
    let formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    formData.append("otp", otp);
    return this.doFetchWithoutToken("post", `${this.getBaseRoute()}/login`, formData);
  };

  logout = () => {
    return this.doFetch("get", `${this.getBaseRoute()}/logout`);
  };

  getMe = () => {
    return this.doFetch("get", `${this.getAuthRoute()}`);
  };

  // checkin

  getCheckins = ({ page = 1, size = 10, start, end, search_info }) => {
    const paramsStr = this.paramsHelper({
      start,
      end,
      search_info,
    });
    return this.doFetch("get", `${this.getCheckinRoute()}/search?page=${page}&size=${size}${paramsStr}`);
  };

  getCheckin = id => {
    return this.doFetch("get", `${this.getCheckinRoute()}/${id}`);
  };

  createCheckin = ({
    name,
    access_card_id,
    card_id,
    address,
    phone,
    born,
    gender,
    nationality,
    note,
    email,
    company_id,
    floor,
    city,
    district,
    ward,
    check_in_time,
  }) => {
    const data = this.removeFalsy({
      name,
      access_card_id,
      card_id,
      address,
      phone,
      born,
      gender,
      note,
      email,
      company_id,
      nationality,
      city,
      district,
      ward,
      floor,
      check_in_time,
    });
    return this.doFetch("post", `${this.getBaseRoute()}/checkin_manual`, data);
  };

  updateCheckin = ({ id, skipped_id_scan, ...rest }) => {
    const data = this.removeFalsy({
      ...rest,
    });
    return this.doFetch("put", `${this.getCheckinRoute()}/${id}`, {
      skipped_id_scan,
      ...data,
    });
  };

  updateCheckinAndVisitor = (form1, form2) => {
    return Promise.all([
      this.doFetch("put", `${this.getCheckinRoute()}/${form1.id}`, form1),
      this.doFetch("put", `${this.getVisitorRoute()}/${form2.id}`, form2),
    ]);
  };

  deleteCheckin = id => {
    return this.doFetch("delete", `${this.getCheckinRoute()}/${id}`);
  };

  declineCheckin = ({ id, decline_approve_reason }) => {
    return this.doFetch("put", `${this.getCheckinRoute()}/${id}/decline_approve`, {
      id,
      decline_approve_reason,
    });
  };

  exportCheckinsExcel = ({ start, end, company }) => {
    const paramsStr = this.paramsHelper({ company });
    return this.downloadFile(
      `${this.getCheckinRoute()}/search?start=${start}&end=${end}${paramsStr}&export=true`,
      "checkins.xlsx"
    );
  };

  getCheckinHistory = ({ page = 1, size = 10, visitor_id }) => {
    return this.doFetch("get", `${this.getCheckinRoute()}/search?page=${page}&size=${size}&visitor_id=${visitor_id}`);
  };

  // visitor

  getVisitors = ({ page = 1, size = 10, groups }) => {
    const data = this.removeFalsy({
      groups,
    });
    return this.doFetch("post", `${this.getVisitorRoute()}`, data);
  };

  getVisitor = id => {
    return this.doFetch("get", `${this.getVisitorRoute()}/${id}`);
  };

  createVisitor = ({ timezone, company_id, email, phone, name, groups }) => {
    const data = this.removeFalsy({
      timezone,
      company_id,
      email,
      phone,
      name,
      groups,
    });
    return this.doFetch("post", `${this.getVisitorRoute()}/create`, data);
  };

  updateVisitor = ({ id, name, card_id, access_card_id, born, gender, address, nationality, hometown }) => {
    const data = this.removeFalsy({
      name,
      card_id,
      access_card_id,
      born,
      gender,
      address,
      nationality,
      hometown,
    });
    return this.doFetch("put", `${this.getVisitorRoute()}/${id}`, data);
  };

  deleteVisitor = id => {
    return this.doFetch("delete", `${this.getVisitorRoute()}/${id}`);
  };

  // user

  getUsers = ({ page = 1, size = 10, groups, name, company }) => {
    const data = this.removeFalsy({
      groups,
      name,
      company,
    });
    return this.doFetch("post", `${this.getUserRoute()}?page=${page}&size=${size}`, data); // group: Building Admin, Kiosk, Company Employee, Front Desk
  };

  getUser = id => {
    return this.doFetch("get", `${this.getUserRoute()}/${id}`);
  };

  createUser = ({ timezone, company_id, email, phone, name, groups }) => {
    const data = this.removeFalsy({
      timezone,
      company_id,
      email,
      phone,
      name,
      groups,
    });
    return this.doFetch("post", `${this.getUserRoute()}/create`, data);
  };

  updateUser = ({ id, company, email, phone, name, groups }) => {
    const data = this.removeFalsy({
      company,
      email,
      phone,
      name,
      groups,
    });
    return this.doFetch("put", `${this.getUserRoute()}/${id}`, data);
  };

  deleteUser = id => {
    return this.doFetch("delete", `${this.getUserRoute()}/${id}`);
  };

  importStaffsExcel = file => {
    let formData = new FormData();
    formData.append("file", file);
    return this.doFetch("post", `${this.getBaseRoute()}/staffs/import`, formData);
  };
  exportStaffsExcel = () => {
    return this.downloadFile(`${this.getBaseRoute()}/staffs/export`, "staffs.xlsx");
  };

  changePassword = (old_password, new_password) => {
    let formData = new FormData();
    formData.append("old_password", old_password);
    formData.append("new_password", new_password);
    formData.append("web", true);
    return this.doFetch("put", `${this.getUserRoute()}/change_password`, formData);
  };

  changePasswordApp = new_password => {
    let formData = new FormData();
    formData.append("new_password", new_password);
    return this.doFetch("put", `${this.getUserRoute()}/change_password`, formData);
  };

  resetPassword = email => {
    return this.doFetchWithoutToken("post", `${this.getBaseRoute()}/password_reset`, { email });
  };

  resetPasswordConfirm = (password, token) => {
    return this.doFetchWithoutToken("post", `${this.getBaseRoute()}/password_reset_confirm`, { password, token });
  };

  getStatistics = month => {
    return this.doFetch("get", `${this.getBaseRoute()}/dashboard/summary?month=${month}`);
  };

  // question

  getQuestions = () => {
    return this.doFetch("post", `${this.getBaseRoute()}/survey`);
  };

  createQuestion = ({ vietnamese, english }) => {
    const data = this.removeFalsy({
      vietnamese,
      english,
    });
    return this.doFetch("post", `${this.getQuestionRoute()}`, data);
  };

  updateQuestion = ({ id, vietnamese, english }) => {
    const data = this.removeFalsy({
      vietnamese,
      english,
    });
    return this.doFetch("put", `${this.getQuestionRoute()}/${id}`, data);
  };

  deleteQuestion = id => {
    return this.doFetch("delete", `${this.getQuestionRoute()}/${id}`);
  };

  // building

  getBuildings = ({ page = 1, size = 10, groups, name, company }) => {
    const data = this.removeFalsy({
      groups,
      name,
      company,
    });
    return this.doFetch("post", `${this.getBuildingRoute()}?page=${page}&size=${size}`, data);
  };

  getBuilding = id => {
    return this.doFetch("get", `${this.getBuildingRoute()}/${id}`);
  };

  createBuilding = ({ timezone, company_id, email, phone, name, groups }) => {
    const data = this.removeFalsy({
      timezone,
      company_id,
      email,
      phone,
      name,
      groups,
    });
    return this.doFetch("post", `${this.getBuildingRoute()}/create`, data);
  };

  updateBuilding = ({ id, skip_survey, survey_questions }) => {
    return this.doFetch("put", `${this.getBuildingRoute()}/${id}`, { skip_survey, survey_questions });
  };

  deleteBuilding = id => {
    return this.doFetch("delete", `${this.getBuildingRoute()}/${id}`);
  };

  // contact

  getContact = id => {
    return this.doFetch("get", `${this.getContactRoute()}/${id}`);
  };

  updateContact = (id, formData) => {
    return this.doFetch("put", `${this.getContactRoute()}/${id}`, formData);
  };

  deleteContact = id => {
    return this.doFetch("delete", `${this.getContactRoute()}/${id}`);
  };

  blockContact = (id, block) => {
    return this.doFetch("get", `${this.getContactRoute()}/block/${id}?block=${block}`);
  };

  searchContacts = ({ start, end, name, page = 1, size = 12, contact_status = "checked_in", blocked }) => {
    const paramsStr = this.paramsHelper({ start, end, name, blocked });
    return this.doFetch(
      "get",
      `${this.getContactRoute()}/search?page=${page}&size=${size}&contact_status=${contact_status}${paramsStr}`
    );
  };

  confirmContact = (id, formData) => {
    return this.doFetch("post", `${this.getBaseRoute()}/confirm/${id}`, formData);
  };

  checkInContact = formData => {
    return this.doFetch("post", `${this.getBaseRoute()}/check_in`, formData);
  };

  // company

  getCompanies = ({ floor, name }) => {
    const paramsStr = this.paramsHelper({
      floor,
      name,
    });
    return this.doFetch("get", `${this.getCompanyRoute()}?${paramsStr}`);
  };
  getCompany = id => {
    return this.doFetch("get", `${this.getCompanyRoute()}/${id}`);
  };
  createCompany = ({ name, display_name, floors, building_id = 1 }) => {
    return this.doFetch("post", `${this.getCompanyRoute()}`, {
      name,
      display_name,
      floors,
      building_id,
    });
  };
  updateCompany = (id, info) => {
    return this.doFetch("put", `${this.getCompanyRoute()}/${id}`, info);
  };
  deleteCompany = id => {
    return this.doFetch("delete", `${this.getCompanyRoute()}/${id}`);
  };
  importCompaniesExcel = file => {
    let formData = new FormData();
    formData.append("file", file);
    return this.doFetch("post", `${this.getCompanyRoute()}/import`, formData);
  };
  exportCompaniesExcel = () => {
    return this.downloadFile(`${this.getCompanyRoute()}/export`, "companies.xlsx");
  };
  registerContact = info => {
    return this.doFetch("post", `${this.getBaseRoute()}/register`, info);
  };

  // device

  getDevices = () => {
    return this.doFetch("get", `${this.getDeviceRoute()}`);
  };

  acceptDeviceConnection = code_number => {
    return this.doFetch("post", `${this.getDeviceRoute()}/accept_device`, {
      code_number,
    });
  };

  removeDeviceConnection = id => {
    return this.doFetch("put", `${this.getDeviceRoute()}/${id}/remove_connection`);
  };

  // invites

  getInvites = ({ page = 1, size = 10, company_id, confirmed_status, search_info, start, end, my_invite }) => {
    const data = this.removeFalsy({
      company_id,
      confirmed_status,
      search_info,
      start,
      end,
      my_invite,
    });
    return this.doFetch("post", `${this.getInviteRoute()}/search?page=${page}&size=${size}`, data);
  };

  getInvite = id => {
    return this.doFetch("get", `${this.getInviteRoute()}/${id}`);
  };

  createInvite = ({ company_id, floor, invitee_name, schedule_checkin_time, email, phone, note }) => {
    const data = this.removeFalsy({
      company_id,
      floor,
      invitee_name,
      schedule_checkin_time,
      email,
      phone,
      note,
    });
    return this.doFetch("post", `${this.getBaseRoute()}/pre-register`, data);
  };

  updateInvite = ({ id, ...rest }) => {
    const data = this.removeFalsy({
      ...rest,
    });
    return this.doFetch("put", `${this.getInviteRoute()}/${id}`, data);
  };

  deleteInvite = id => {
    return this.doFetch("delete", `${this.getInviteRoute()}/${id}`);
  };

  confirmInvite = ({ id, confirm, decline_reason }) => {
    return this.doFetch("post", `${this.getInviteRoute()}/${id}/confirm`, {
      confirm,
      decline_reason,
    });
  };

  acceptInvite = ({ id }) => {
    return this.doFetch("post", `${this.getInviteRoute()}/${id}/confirm`, {
      confirm: true,
    });
  };

  declineInvite = ({ id, decline_reason }) => {
    return this.doFetch("post", `${this.getInviteRoute()}/${id}/confirm`, {
      confirm: false,
      decline_reason,
    });
  };

  acceptInvites = invite_ids => {
    return this.doFetch("put", `${this.getInviteRoute()}/confirm`, {
      confirm: true,
      invite_ids,
    });
  };

  declineInvites = invite_ids => {
    return this.doFetch("put", `${this.getInviteRoute()}/confirm`, {
      confirm: false,
      invite_ids,
    });
  };

  // billing

  getBilling = () => {
    return this.doFetch("get", `${this.getBaseRoute()}/billing`);
  };

  // image

  getIdImage = id => {
    return this.getImage(`${this.getBaseRoute()}/images/id_photo/${id}`);
  };

  getSelfieImage = id => {
    return this.getImage(`${this.getBaseRoute()}/images/selfie_photo/${id}`);
  };

  getImage = url => {
    return axios({
      method: "get",
      url,
      headers: {
        Authorization: "Bearer " + this.token,
      },
      responseType: "blob",
    }).then(res => {
      return new Blob([res.data]);
    });
  };

  doFetch = (method, url, data = {}) => {
    return axios({
      method,
      url,
      data,
      headers: {
        Authorization: "Bearer " + this.token,
      },
    }).then(res => {
      return res.data;
    });
  };
  doFetchWithoutToken = (method, url, data = {}) => {
    return axios({
      method,
      url,
      data,
    }).then(res => {
      return res.data;
    });
  };
  downloadFile = (url, filename) => {
    return axios({
      method: "get",
      url,
      headers: {
        Authorization: "Bearer " + this.token,
      },
      responseType: "blob",
    })
      .then(res => {
        // 2. Create blob link to download
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        // 3. Append to html page
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch(error => {
        if (error.response) {
          return error.response.data;
        }
        if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  };
}
