import { combineReducers } from "redux";
import { ContactTypes } from "../../action_types";
import { arrayToObject } from "@app/utils/convert_data";

function contacts(state = {}, action) {
  switch (action.type) {
    case ContactTypes.SEARCH_CONTACTS_SUCCESS:
      return arrayToObject(action.data.contacts);
    case ContactTypes.GET_CONTACT_SUCCESS:
      return action.data;
    case ContactTypes.UPDATE_CONTACT_SUCCESS:
    case ContactTypes.BLOCK_CONTACT_SUCCESS:
      return {
        ...state,
        [action.data.id]: action.data,
      };
    case ContactTypes.CONFIRM_CONTACT_SUCCESS:
    case ContactTypes.DELETE_CONTACT_SUCCESS:
      const { [action.id]: _, ...newState } = state;
      return newState;
    default:
      return state;
  }
}

function meta(
  state = {
    current_page: 0,
    total: 0,
    name: "",
    startDate: null,
    endDate: null,
    confirm: "",
    blocked: "",
  },
  action
) {
  switch (action.type) {
    case ContactTypes.SEARCH_CONTACTS_SUCCESS:
      return {
        ...state,
        current_page: action.data.current_page,
        total: action.data.total,
        name: action.name,
        startDate: action.startDate,
        endDate: action.endDate,
      };
    case ContactTypes.CONFIRM_CONTACT_SUCCESS:
      return {
        ...state,
        confirm: action.confirm,
      };
    case ContactTypes.BLOCK_CONTACT_SUCCESS:
      return {
        ...state,
        blocked: action.blocked,
      };
    case ContactTypes.DELETE_CONTACT_SUCCESS: {
      const { total } = state;
      return {
        ...state,
        total: total - 1,
      };
    }
    default:
      return state;
  }
}

export default combineReducers({
  contacts,
  meta,
});
