export const PUBLIC_ROUTE = {
  LANDING: "/sign-in",
  SIGN_IN: "/sign-in",
  SIGN_UP: "/signup",
  FORGET_PASSWORD: "/forget-password",
  VERIFY_EMAIL: "/sign-in/confirmation",
  RESET_PASSWORD: "/reset-password",
  RESET_PASSWORD_SUCCESS: "/reset-password-success",
  PAGE_404: "/404",
  PAGE_500: "/500",
  AUTH0_CALLBACK: "/auth0loginCallback",
  CHANGE_PASSWORD: "/change-password",
  WELCOME: "/welcome",
  FIRST_LOGIN: "/first-login",
  ERROR_PAGE: "/error",
};

export const PRIVATE_ROUTE = {
  DASHBOARD: "/",
};
